import { Banner, BREAKPOINTS, Button, COLORS, HyperLink, IconButton, LoadingIndicator, PageWidth, Size, SystemIcons, useToast } from "@laerdal/life-react-components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { KnowledgeArticleDto } from "../../model/dto/knowledgeArticleDto";
import ArticleApi from "../../services/api/ArticleApi";
import { KnowledgeArticleListItemDto } from "../../model/dto/knowledgeArticleListItemDto";
import posthog from 'posthog-js';
import CantFindCreateCase from "../../commonComponents/CantFindCreateCase";
import ArticleFeedback from "../../commonComponents/ArticleFeedback";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectMarket } from "../../store/slices/globalConfigSlice";
import { marketList } from "../../model/constants/marketContants";
import { set } from "react-hook-form";

const Containter = styled.div`
  ${BREAKPOINTS.LARGE}{
    flex-direction: row;
  }
  flex-direction: column;

  flex-grow: 1; */
    /* z-index: 1; */
    /* display: flex; */

  width:100%;
  display: flex;
  column-gap: 64px;
  margin-bottom: 64px;
`;

const FirstColumn = styled.div`
  ${BREAKPOINTS.LARGE}{
    flex: 0 0 700px;
  }
  display: flex;
  flex-direction: column;
`;

const SecondColumn = styled.div`
  display: flex;
  flex-direction: column; 
  width:100%;
`;

const ArticleHtmlContainer = styled.div`
  margin-bottom: 32px;
  img {
    max-width: 100%;
    height: auto !important;
  }
`;

const LoadingIndicatorContainer = styled.div`
  margin: 0 auto;
`;

const ArticlePageContainer = styled.div`
  margin: 0 0 50px 0;
`;

const RelatedArticlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  
  margin: 40px 0 0 0;
`;

const DesktopH6 = styled.div`
  font-family: Lato;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 30px */
  margin-bottom:8px;
`;

const LinkWrapper = styled.div`
  margin:  0 0 16px 0;
`;

const TranslateContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ArticlePage = () => {
  const { product, category, articleNumber, title } = useParams<{ product: string, category:string, articleNumber: string, title:string }>();
  const { t } = useTranslation(["articles", "translation"]);
  
  const navigate = useNavigate()
  const location = useLocation();
  const [article, setArticle] = useState<KnowledgeArticleDto | undefined>(undefined);
  const [loadingArticle, setLoadingArticle] = useState<boolean>(true);
  const [articles, setArticles] = useState<KnowledgeArticleListItemDto[]>([]);
  const [articlesLoading, setArticlesLoading] = useState<boolean>(true);
  const [translate, setTranslate] = useState<boolean>(false);

  const currentMartket = useSelector(selectMarket);
  
  useEffect(() => {
    
    setArticle(undefined);
    setArticles([]);
    setLoadingArticle(true);
    setArticlesLoading(true);

    ArticleApi.GetKnowledgeArticle(articleNumber ?? '', title, undefined, translate ? marketList[currentMartket].locale : undefined)
      .then((data) => {
        console.log("currentURL", location.pathname)
        
        if(!!data.publicContent){
          data.publicContent = data.publicContent.replaceAll("<table>", '<div style="width: calc(100% - 32px); overflow-x: auto; -webkit-overflow-scrolling: touch;"><table>') ;
          data.publicContent = data.publicContent.replaceAll("</table>", '</table></div>');
          
          if(currentMartket != 'en' ){
            data.publicContent = data.publicContent.replaceAll(`href="/`, `href="/${currentMartket}/`);
            data.publicContent = data.publicContent.replaceAll("{{currentURL}}", location.pathname) 
          }
          else{
            data.publicContent = data.publicContent.replaceAll("{{currentURL}}", location.pathname) ;
          }
        }
        
        setArticle(data);
        setLoadingArticle(false);
        posthog.capture('ArticleView',{
          currentArticle: articleNumber,
          version: 'web'
        });

        ArticleApi.GetKnowledgeArticles(6, 0, data.title, '', undefined,  translate ? marketList[currentMartket].locale : undefined)
          .then((data) => {
            setArticles(data.items.filter(x => x.articleNumber != articleNumber));
            setArticlesLoading(false);
          });
        });
    }, [product, articleNumber, translate]);

  return (
    <>
      <ArticlePageContainer>
        <PageWidth useMaxWidth={true} maxWidth={1024}>
          <Button 
            variant="tertiary" 
            icon={<SystemIcons.ArrowLineLeft />} 
            size={Size.Small} 
            onClick={() => {
              //@ts-ignore
              posthog.capture('SUPP-ArticlePage Back',{
                currentArticle: articleNumber,
              });
              window.history.back();
            }}>
            {t('Back')}
          </Button>
          
          <Containter>
            { (articlesLoading || articlesLoading ) &&
              <LoadingIndicatorContainer>
                <LoadingIndicator />
              </LoadingIndicatorContainer> 
            }
            
            { !articlesLoading && !articlesLoading &&
            
              <FirstColumn>
                {
                  marketList[currentMartket].locale != 'en' &&
                  <>
                    <TranslateContainer>
                      <Button 
                        variant="tertiary" 
                        size={Size.Small} 
                        onClick={() => {
                          //@ts-ignore
                          posthog.capture('SUPP-TranslateContent',{
                            currentArticle: articleNumber,
                            market: selectMarket,
                            language: marketList[currentMartket].language,
                          });
                          setTranslate(!translate);
                        }}>
                          {!translate ? t('Translate with AI') : t('Show original')}
                      </Button>
                    </TranslateContainer>
                    { translate &&
                      <Banner 
                        type="neutral"
                        linkText={t('See original')}
                        linkAction={() => setTranslate(false)}
                        link={location.pathname}
                        fullWidth={true}
                        >
                          {t('This content has been translated using AI and may contain inaccuracies. Please refer to the original English version for accuracy.')}
                      </Banner>
                    }
                  </>
                }
                {article && <h1>{article.title}</h1>}
                {article && 
                  <ArticleHtmlContainer 
                    dangerouslySetInnerHTML={{__html: article.publicContent ?? ''}}/>
                }

                { article && <ArticleFeedback articleNumber={article.articleNumber} positiveFeedbackCount={article.positiveFeedbackCount} totalFeedbackCount={article.feedbackCount}  />}
              </FirstColumn>
            }

            { !articlesLoading && !articlesLoading &&
              <SecondColumn>
                <RelatedArticlesContainer>
                  <DesktopH6>
                    {t('Related articles')}
                  </DesktopH6>
                  
                  { 
                        articles.map(item =>
                        (
                          <LinkWrapper key={`article-${item.articleNumber}`} >
                            <HyperLink  
                              onClick={(e)=> {
                                e.preventDefault()
                                e.stopPropagation()
                                // @ts-ignore
                                posthog.capture('ArticlePage OpenRelatedArticle',{
                                  currentArticle: articleNumber,
                                  relatedArticle: item.articleNumber,
                                });
                                navigate(`/articles/${item.articleNumber}`)
                              }}
                              id="HyperLinkDefault" 
                              variant="default" 
                              href={`#`}  >

                              {item.title}
                            </HyperLink>
                        </LinkWrapper>
                        ))
                  }
                  {!articlesLoading && articles.length == 0 && "No related articles"}
                </RelatedArticlesContainer>
              </SecondColumn>
            }
          </Containter>
          <CantFindCreateCase />
        </PageWidth>
      </ArticlePageContainer>

      
    </>);
};

export default ArticlePage;
