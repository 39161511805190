import { configureStore } from '@reduxjs/toolkit';
import globalConfigReducer from './slices/globalConfigSlice';
import userReducer from './slices/userSlice';
import rootReducer from './reducers';

export const store = configureStore({
    reducer: {
        globalConfig: globalConfigReducer,
        user: userReducer
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
