import { BackButton, BREAKPOINTS, Button, COLORS, ComponentL, ComponentM, ComponentTextStyle, HyperLink, PageWidth, Size, SystemIcons } from "@laerdal/life-react-components";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import UrgentAssistance from "../../commonComponents/UrgentAssistance";
import { useTranslation } from "react-i18next";

const MainFrame = styled.div`

    ${BREAKPOINTS.LARGE}{
        width: 700px;
    }
    

    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;

    border-radius: 8px;
    border: 1px solid ${COLORS.neutral_200};

        align-self: center;

`;

const TextContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    gap 16px;
`;

const Title = styled.div`
    color: #000;

    font-feature-settings: 'liga' off;
    /* L - Desktop/Hero P */
    font-family: Lato;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 45px */
`;

const BackHome = styled(Button)`
        align-self: center;
`;

const Link = styled(HyperLink)`
    svg {
        vertical-align: middle;
        margin-bottom: 4px;
    }
`;

const Ilustration = styled.img`
    
    ${BREAKPOINTS.MEDIUM}{
        width: unset;
    }
    width: 100%;
`;
const Header = styled.div`
  display: flex;
  padding: 32px 0;
  flex-direction: column;
  align-items: flex-start;
`
const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const FullWidth = styled.div`
  border-top: 1px solid ${COLORS.neutral_200};
  background: ${COLORS.neutral_20};
`


const CaseConfirmationPage = () => {
    const { caseNumber } = useParams<{ caseNumber: string}>();
    const navigate = useNavigate();
    const { t } = useTranslation("cases");
    
    return (
        <> 
            <PageWidth useMaxWidth={true} maxWidth={1024}>
                <FlexContainer>

                    <Header>
                        
                    </Header>

                    <MainFrame>
                        <Ilustration src="assets/caseConofirmationIlustration.svg"></Ilustration>

                        <TextContent>
                            <Title>{t('Thank You for Reaching Out')}</Title>
                            <ComponentL color={COLORS.primary_600} textStyle={ComponentTextStyle.Bold}>{t('Your reference number')} #{caseNumber}</ComponentL>
                            <ComponentM>{t("We've received your request and our team will review it shortly. We appreciate you taking the time to provide these details.")}</ComponentM>
                            <BackHome onClick={() => navigate('/')}>{t('Back to Homepage')}</BackHome>
                            <ComponentM>
                                {t('Already have an account? To view the status of your request')}  
                                <Link target="_blank" href={`${process.env.REACT_APP_ACCOUNT_BASE}/cases/details/${caseNumber}`} variant="default">
                                    {t('Sign in here')} <SystemIcons.OpenNewWindow size="22px"/>
                                </Link>
                            </ComponentM>
                        </TextContent>
                    </MainFrame>
                </FlexContainer>

            </PageWidth>
            
            <FullWidth>
                <PageWidth useMaxWidth={true} maxWidth={1024}>
                <UrgentAssistance />
                </PageWidth>
            </FullWidth>
        </>);
};

export default CaseConfirmationPage;