import { BackButton, BREAKPOINTS, COLORS, ComponentL, ComponentTextStyle, ContentIcons, DropdownFilter, HorizontalTabs, LoadingIndicator, PageWidth, Size, VerticalTabEntry, VerticalTabs } from "@laerdal/life-react-components";
import CantFindCreateCase from "../../commonComponents/CantFindCreateCase";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ArticleApi from "../../services/api/ArticleApi";
import { PageWidthFull } from "../../commonComponents/PageWidthFull";
import { ProductRelevantArticlesDto } from "../../model/dto/productRelevantArticlesDto";
import { ProductLineDetailsDto } from "../../model/dto/productLineDetailsDto";
import {useMediaMatch} from "rooks";
import { useTranslation } from "react-i18next";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
  margin-bottom: 64px;
`;

const Header = styled.div`
  display: flex;
  padding: 32px 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
`;

const VerticalTabsContainer = styled.div`
  display: flex;
  width: 280px;
`;
const Articles = styled.div`
  display: flex;
  flex-direction: column;
  gap 12px;
  width:100%;
`;
const LoadingIndicatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`;
const FaqLink = styled.a`
  &:link, &:visited, &:hover, &:active {
    color: ${COLORS.primary_600};
    text-decoration: none; 
  }

  color: ${COLORS.primary_600};
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; 
`;

const ProductLineArticlesPage = () => {
    const { categoryLink, productLink, componentLink } = useParams<{ categoryLink: string, productLink: string, componentLink: string }>();
    const { t } = useTranslation("landingPage");
  
    const navigate = useNavigate()
    const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));
    
    const [loadingArticles, setLoadingArticles] = useState<boolean>(true);
    const [articlesDto, setArticlesDto] = useState<ProductRelevantArticlesDto | undefined>(undefined);
    
    const [productLineLoading, setProductLineLoading] = useState<boolean>(true);
    const [productLine, setProductLine] = useState<ProductLineDetailsDto | undefined>(undefined);

    useEffect(() => {
        setProductLineLoading(true);
        setProductLine(undefined);

        if(categoryLink && productLink)
        {
            ArticleApi
                .GetProductLine(categoryLink, productLink)
                .then((data) => {
                setProductLineLoading(false);
                setProductLine(data);
                })
        }
    }, [categoryLink, productLink]);
        
    useEffect(() => {
        setLoadingArticles(true);
        setArticlesDto(undefined);

        if(categoryLink && productLink && componentLink)
        {
            ArticleApi
                .GetRelevantArticlesLine(categoryLink, productLink, componentLink)
                .then((data) => {
                    setLoadingArticles(false);
                    setArticlesDto(data);
                })
        }
    }, [categoryLink, productLink, componentLink]);


    
    return (
      <>
        { productLineLoading &&
            <LoadingIndicator ></LoadingIndicator>
        }

        { productLine &&
            <>
                <PageWidthFull useMaxWidth={true} maxWidth={1024} >
                    <Header>
                        <BackButton size={Size.XSmall} onClick={()=>window.history.back()}>{t('Back')}</BackButton>
                    </Header>
                    <PageContainer>
                        <ComponentL textStyle={ComponentTextStyle.Bold}> {t('Articles for')} {productLine.displayName}</ComponentL>
                        { !isMediumScreen && 
                            <DropdownFilter 
                                value={componentLink}
                                list={productLine.components.map((x)=> {return {
                                    value: x.link,
                                    displayLabel: x.name
                                }})}
                                onSelect={(value) => value && navigate(`/category/${categoryLink}/product/${productLink}/articles/${value}`, {replace: true})}
                                >
                            </DropdownFilter>
                        }
                        <Row>
                            {isMediumScreen && <VerticalTabsContainer>
                                <VerticalTabs entries={
                                    productLine.components.map((x)=> {return {
                                        requiredLine: x.name,
                                        onClick: (e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            navigate(`/category/${categoryLink}/product/${productLink}/articles/${x.link}`, {replace: true})
                                        },
                                        to: `/category/${categoryLink}/product/${productLink}/articles/${x.link}`
                                    }})
                                }></VerticalTabs>
                            </VerticalTabsContainer>}
                            <Articles>
                                { loadingArticles &&
                                    <LoadingIndicatorContainer>
                                        <LoadingIndicator ></LoadingIndicator>
                                    </LoadingIndicatorContainer>
                                }
                                { !loadingArticles && articlesDto?.articles.map((item, index) =>
                                    <FaqLink 
                                        onClick={(e)=>{
                                            e.preventDefault();
                                            navigate(`/articles/Knowledge/${item.link}`);
                                        }}
                                        href={`/articles/Knowledge/${item.link}`} 
                                        key={`link-${index}`}>
                                        {item.name}
                                    </FaqLink>)
                                }
                            </Articles>
                        </Row>

                    </PageContainer>
                    <CantFindCreateCase />
                </PageWidthFull>
            </>
        }
      </>);
  };
  
  export default ProductLineArticlesPage;