import { Button, COLORS, ComponentL, ComponentM, GlobalNavigationBar, IconButton, SystemIcons } from '@laerdal/life-react-components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Austria, Belgium, Brasil, Canada, China, Denmark, Deutschland, Finland, France, HongKong, India, International, Italia, Japan, Korea, LatinAmerica, Malasia, Mexico, Nederland, NewZealand, Norge, Polish, Singapore, Spain, Sweeden, Switzarland, UK, USA } from './Flags';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocale, selectMarket, setMarket } from '../store/slices/globalConfigSlice';
import { marketList } from '../model/constants/marketContants';
import { useNavigate } from 'react-router-dom';


const Container = styled.div`
  background: ${COLORS.neutral_700};;
  display: flex;
  
  height: 55px;
  
  align-items: center;
`;

const MainButton = styled.div`
  margin: 8px 50px;
  padding: 0px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
  cursor: pointer;
  color: ${COLORS.primary_20};

  &:hover {
    border-radius: 4px;
    background: ${COLORS.primary_50};
    color: ${COLORS.primary_700};
  }
`;

const DropdownContainer = styled.div<{ isOpen: boolean }>`
  border-top: 1px solid ${COLORS.primary_200};
  position: absolute;
  top: 55px;
  background: ${COLORS.black};
  display: ${props => props.isOpen ? 'flex' : 'none'};
  z-index: 100000;
  
  width: calc(100% - 124px);
  padding: 64px;
  flex-direction: row;
  

  color: ${COLORS.white};
`;

const H3 = styled.div`
  flex-direction: column;
  display: flex;
  max-width: 275px;
  flex-grow: 1;
  gap: 16px;
  font-size: 40px;
`;

const Line = styled.div`
  width: 79px;
  height: 1px;
  background: ${COLORS.white};
`;

const Column = styled.div`
  flex-direction: column;
  display: flex;
  max-width: 275px;
  flex-grow: 1;
  gap: 16px;
`;

const Country = styled.div`
  
  padding: 4px 8px;
  justify-content: start;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
  cursor: pointer;
  color: ${COLORS.primary_20};

  &:hover {
    border-radius: 4px;
    background: ${COLORS.primary_50};
    color: ${COLORS.primary_700};
  }
`;

const LanguageSelector = () => {
    const { t } = useTranslation('translation');
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    
    const currentMarket: keyof typeof marketList = useSelector(selectMarket);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleCountryClick = (marketCode: string) => {
      dispatch(setMarket(marketCode));
      setIsOpen(false);
      navigate(`/${marketCode}`)
    };

    return (
        <Container ref={containerRef}>
            <MainButton onClick={() => setIsOpen(!isOpen)}>
                {marketList[currentMarket].icon}
                <div>
                    { marketList[currentMarket].marketName } 
                </div>
                <SystemIcons.ArrowDropDown size='24px'/>
            </MainButton>
            <DropdownContainer isOpen={isOpen}>
                <Column>
                  
                  <IconButton 
                    variant='secondary'
                    useTransparentBackground={true}
                    shape='circular'
                    action={() => setIsOpen(false)}  >
                    <SystemIcons.Close />
                  </IconButton>
                  <Line/>
                  <H3>
                    Country & language selector
                  </H3>
                </Column>

                <Column>
                  <ComponentL color={COLORS.white}>Americas</ComponentL>
                  <Country onClick={() => handleCountryClick('la')}>{marketList['la'].icon} {marketList['la'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('br')}>{marketList['br'].icon} {marketList['br'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('ca')}>{marketList['ca'].icon} {marketList['ca'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('mx')}>{marketList['mx'].icon} {marketList['mx'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('us')}>{marketList['us'].icon} {marketList['us'].marketName}</Country>

                  <ComponentL color={COLORS.white}>Asia / Pacific</ComponentL>
                  <Country onClick={() => handleCountryClick('au')}>{marketList['au'].icon} {marketList['au'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('cn')}>{marketList['cn'].icon} {marketList['cn'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('hk')}>{marketList['hk'].icon} {marketList['hk'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('in')}>{marketList['in'].icon} {marketList['in'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('jp')}>{marketList['jp'].icon} {marketList['jp'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('kr')}>{marketList['kr'].icon} {marketList['kr'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('my')}>{marketList['my'].icon} {marketList['my'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('nz')}>{marketList['nz'].icon} {marketList['nz'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('sg')}>{marketList['sg'].icon} {marketList['sg'].marketName}</Country>
                </Column>

                <Column>
                  <ComponentL color={COLORS.white}>Europe</ComponentL>
                  <Country onClick={() => handleCountryClick('be-nl')}>{marketList['be-nl'].icon} {marketList['be-nl'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('be-fr')}>{marketList['be-fr'].icon} {marketList['be-fr'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('dk')}>{marketList['dk'].icon} {marketList['dk'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('de')}>{marketList['de'].icon} {marketList['de'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('es')}>{marketList['es'].icon} {marketList['es'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('fr')}>{marketList['fr'].icon} {marketList['fr'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('it')}>{marketList['it'].icon} {marketList['it'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('nl')}>{marketList['nl'].icon} {marketList['nl'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('no')}>{marketList['no'].icon} {marketList['no'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('at')}>{marketList['at'].icon} {marketList['at'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('pl')}>{marketList['pl'].icon} {marketList['pl'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('ch')}>{marketList['ch'].icon} {marketList['ch'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('fi')}>{marketList['fi'].icon} {marketList['fi'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('se')}>{marketList['se'].icon} {marketList['se'].marketName}</Country>
                  <Country onClick={() => handleCountryClick('gb')}>{marketList['gb'].icon} {marketList['gb'].marketName}</Country>
                </Column>

                <Column>
                  <ComponentL color={COLORS.white}>Location not listed?</ComponentL>
                  <Country onClick={() => handleCountryClick('')}>{marketList[''].icon} {marketList[''].marketName}</Country>
                </Column>
                
            </DropdownContainer>
        </Container>
    );
}

export default LanguageSelector;