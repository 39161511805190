import { DesktopNavigationMenuProps, GlobalNavigationBar, MobileNavigationMenuProps } from '@laerdal/life-react-components';
import { useTranslation } from 'react-i18next';
import { Brasil, USA } from './Flags';
import { useEffect, useState } from 'react';
import { selectLocale } from '../store/slices/globalConfigSlice';
import { useSelector } from 'react-redux';

const Header = () => {
    const { t, i18n } = useTranslation('translation');
    const [desktopMenu, setDesktopMenu] = useState<DesktopNavigationMenuProps>();
    const [mobileMenu, setMobileMenu] = useState<MobileNavigationMenuProps>();
    const currentLocale = useSelector(selectLocale);
    
    useEffect(() => {
      console.log('Header useEffect');

      setDesktopMenu({
        items: [
          {
            label: t('Home'),
            to: '/',
            exact: true
          }   
        ]
      });
      
      setMobileMenu({
        items: [
          {
            label: t('Home'),
            to: '/',
            exact: true
          }
        ]
      });
    }, [i18n.language, currentLocale]);
    
    const navigation = {
        items: [
          {
            label: t('Home'),
            to: '/',
            exact: true
          }   
        ]
      } as any;

    const mobileNavigation = {
      items: [
        {
          label: t('Home'),
          to: '/',
          exact: true
        },
        {
          icon: <Brasil/>,
          label: 'Brasil',
          to: '/',
          exact: true,
          action: () => {console.log('aaa')},
        }   
      ]
    } as any;

    return (
        <GlobalNavigationBar
            isAuthenticated={true}
            name={t('Support')}
            useMaxWidth={true}
            desktop={desktopMenu}
            mobile={mobileMenu}
        />
    );
}

export default Header;