import ReactDOM from 'react-dom';
import { BrowserRouter, createBrowserRouter, ScrollRestoration } from 'react-router-dom';
import {createRoot} from 'react-dom/client';
import App from './App';
import './index.css';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { AppInsightsContext, ReactPlugin} from '@microsoft/applicationinsights-react-js';
import { ToastProvider } from '@laerdal/life-react-components';
import posthog from 'posthog-js';
import LandingPage from './pages/landingPage/LandingPage';
import ProductCategoryPage from './pages/landingPage/ProductCategoryPage';
import ProductLinePage from './pages/landingPage/ProductLinePage';
import ProductLineArticlesPage from './pages/landingPage/ProductLineArticlesPage';
import { Provider } from 'react-redux';
import store from './store/store';


var reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({ config: {
  connectionString: process.env.REACT_APP_APPLICATIONINSIGHTS,
  enableAutoRouteTracking: true,
  extensions: [reactPlugin],
} });
appInsights.loadAppInsights();

const setPosthogByConsent = () => {  
  //@ts-ignore
  if(window.OnetrustActiveGroups?.indexOf('C0002') >= 0){
    posthog.config.api_host = 'https://anltcs.laerdal.com';
    posthog.config.persistence = 'cookie';
    posthog.config.person_profiles = 'always';
    posthog.startSessionRecording();
    posthog.opt_in_capturing();
  }
  else {
    posthog.capture("Opt out");
    posthog.config.api_host = 'https://hogproxy.laerdal.com/ingest';
    posthog.config.persistence = 'memory';
    posthog.config.person_profiles = 'never';
    posthog.stopSessionRecording();
    posthog.reset();
  }
}

//@ts-ignore
if (!window.posthog) {
  //@ts-ignore
  window.posthog = posthog;
}

setTimeout(()=>{
  //@ts-ignore
  posthog.init(process.env.REACT_APP_POSTHOG_DBU, {
    //@ts-ignore
    api_host: window.OnetrustActiveGroups?.indexOf('C0002') >= 0 ?
                'https://anltcs.laerdal.com'
                : 'https://hogproxy.laerdal.com/ingest',
    ui_host: 'https://eu.posthog.com'
  });

  setPosthogByConsent();

  //@ts-ignore
  window.OneTrust?.OnConsentChanged(() => { 
    //@ts-ignore
    setPosthogByConsent();
  });
  
  if(window.self !== window.top){
    // @ts-ignore
    posthog?.capture('helpU opened',{
      $geoip_disable: true,
    })
  }
}, 1000);

const router = createBrowserRouter([
  {
    path: '/',
    element: <LandingPage />,
  },
  {
    path: '/category/:categoryLink',
    element: <ProductCategoryPage />,
  },
  {
    path: '/category/:categoryLink/product/:productLink',
    element: <ProductLinePage />,
  },
  {
    path: '/category/:categoryLink/product/:productLink/component/:componentLink',
    element: <ProductLinePage />,
  },
  {
    path: '/category/:categoryLink/product/:productLink/articles/:componentLink',
    element: <ProductLineArticlesPage />,
  },

]);

// @ts-ignore
window.supportStartTime = new Date().getTime();
// @ts-ignore
window.supportFullUrl = window.location.href;


const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      
      <ToastProvider>
       <AppInsightsContext.Provider value={reactPlugin}>
        <App />
       </AppInsightsContext.Provider>
      </ToastProvider>
     </BrowserRouter>
  </Provider>
  );

