import { Australia, Austria, Belgium, Brasil, Canada, China, Denmark, Deutschland, Finland, France, HongKong, India, International, Italia, Japan, Korea, LatinAmerica, Malasia, Mexico, Nederland, NewZealand, Norge, Polish, Singapore, Spain, Sweeden, Switzarland, UK, USA } from "../../commonComponents/Flags";

interface Market {
    market: string;
    marketName: string;
    locale: string;
    language: string;
    icon: JSX.Element;
}

export const marketList: { [key: string]: Market } = {
    'la': {
        market: 'la',
        marketName: 'América Latina',
        locale: 'es-ES',
        language: 'Spanish',
        icon: <LatinAmerica/>,
    },
    'at': {
        market: 'at',
        marketName: 'Österreich',
        locale: 'de-DK',
        language: 'german',
        icon: <Austria/>,
    },
    'ch': {
        market: 'ch',
        marketName: 'Schweiz',
        locale: 'de-DK',
        language: 'german',
        icon: <Switzarland/>,
    },
    'br':{
        market: 'br',
        marketName: 'Brasil',
        locale: 'pt-BR',
        language: 'português',
        icon: <Brasil/>,
    },
    'ca':{
        market: 'ca',
        marketName: 'Canada',
        locale: 'en',
        language: 'english',
        icon: <Canada/>,
    },
    'mx':{
        market: 'mx',
        marketName: 'México',
        locale: 'es-ES',
        language: 'español',
        icon: <Mexico/>,
    },
    'us':{
        market: 'us',
        marketName: 'USA',
        locale: 'en',
        language: 'english',
        icon: <USA/>,
    },
    'au':{
        market: 'au',
        marketName: 'Australia',
        locale: 'en',
        language: 'english',
        icon: <Australia/>,
    },
    'hk':{
        market: 'hk',
        marketName: 'Hong Kong',
        locale: 'en',
        language: 'english',
        icon: <HongKong/>,
    },
    'in':{
        market: 'In',
        marketName: 'India',
        locale: 'en',
        language: 'english',
        icon: <India/>,
    },
    'jp':{
        market: 'jp',
        marketName: 'Japan - 日本',
        locale: 'ja-JP',
        language: 'japanese',
        icon: <Japan/>,
    },
    'kr':{
        market: 'kr',
        marketName: 'Korea - 한국어',
        locale: 'ko-KR',
        language: 'korean',
        icon: <Korea/>,
    },
    'my':{
        market: 'my',
        marketName: 'Malaysia',
        locale: 'en',
        language: 'english',
        icon: <Malasia/>,
    },
    'nz':{
        market: 'nz',
        marketName: 'New Zealand',
        locale: 'en',
        language: 'english',
        icon: <NewZealand/>,
    },
    'sg':{
        market: 'sg',
        marketName: 'Singapore',
        locale: 'en',
        language: 'english',
        icon: <Singapore/>,
    },
    'be-nl':{
        market: 'be-nl',
        marketName: 'België (Nederlands)',
        locale: 'nl-NL',
        language: 'dutch',
        icon: <Belgium/>,
    },
    'be-fr':{
        market: 'be-fr',
        marketName: 'Belgique (Français)',
        locale: 'fr-FR',
        language: 'french',
        icon: <Belgium/>,
    },
    'dk':{
        market: 'dk',
        marketName: 'Danmark',
        locale: 'da-DK',
        language: 'danish',
        icon: <Denmark/>,
    },
    'de':{
        market: 'de',
        marketName: 'Deutschland',
        locale: 'de-DE',
        language: 'german',
        icon: <Deutschland/>,
    },
    'es':{
        market: 'es',
        marketName: 'España',
        locale: 'es-ES',
        language: 'spanish',
        icon: <Spain/>,
    },
    'fr':{
        market: 'fr',
        marketName: 'France',
        locale: 'fr-FR',
        language: 'french',
        icon: <France/>,
    },
    'it':{
        market: 'it',
        marketName: 'Italia',
        locale: 'it-IT',
        language: 'italian',
        icon: <Italia/>,
    },
    'nl':{
        market: 'nl',
        marketName: 'Nederland',
        locale: 'nl-NL',
        language: 'dutch',
        icon: <Nederland/>,
    },
    'no':{
        market: 'no',
        marketName: 'Norge',
        locale: 'no-NB',
        language: 'norwegian',
        icon: <Norge/>,
    },
    'pl':{
        market: 'pl',
        marketName: 'Polska',
        locale: 'pl-PL',
        language: 'polish',
        icon: <Polish/>,
    },
    'cn':{
        market: 'cn',
        marketName: 'China - 简体中文',
        locale: 'zh-CN',
        language: 'chinese',
        icon: <China/>,
    },
    'fi':{
        market: 'fi',
        marketName: 'Suomi',
        locale: 'fi-FI',
        language: 'finnish',
        icon: <Finland/>,
    },
    'se':{
        market: 'se',
        marketName: 'Sverige',
        locale: 'sv-SE',
        language: 'swedish',
        icon: <Sweeden/>,
    },
    'gb':{
        market: 'gb',
        marketName: 'United Kingdom',
        locale: 'en',
        language: 'english',
        icon: <UK/>,
    },
    '':{
        market: '',
        marketName: 'International',
        locale: 'en',
        language: 'english',
        icon: <International/>,
    },
}