import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { marketList } from '../../model/constants/marketContants';


interface GlobalConfigState {
    market: string;
    locale: string;
    language: string;
}

const initialState: GlobalConfigState = {
    market: '',
    locale: 'en',
    language: 'English'
};

const globalConfigSlice = createSlice({
    name: 'globalConfig',
    initialState,
    reducers: {
        setMarket: (state, action: PayloadAction<string>) => {
            state.market = action.payload;
            state.locale = marketList[action.payload].locale;
        },
        setLocale: (state, action: PayloadAction<string>) => {
            state.locale = action.payload;
        }
    }
});

// Selectors
export const selectMarket = (state: any) => state?.globalConfig?.market;
export const selectLocale = (state: any) => state?.globalConfig?.locale;

export const { setMarket, setLocale } = globalConfigSlice.actions;
export default globalConfigSlice.reducer;
