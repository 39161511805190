import { COLORS, ContentIcons, LoadingIndicator, PageWidth } from "@laerdal/life-react-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Topics, { TopicItem } from "../../commonComponents/Topics";
import Subtopics from "../../commonComponents/Subtopics";
import Faqs from "../../commonComponents/Faqs";
import ProductSupport from "./components/ProductSupport";
import KnowledgeHub from "./components/KnowledgeHub";
import LaerdalYoutube from "./components/LaerdalYoutube";
import LaerdalPrograms from "./components/LaerdalPrograms";
import LandingHeader from "./components/LandingHeader";
import CantFindCreateCase from "../../commonComponents/CantFindCreateCase";
import GlobalWarenty from "./components/GlobalWarenty";
import { useEffect, useState } from "react";
import { ProductCategoryDto } from "../../model/dto/productCategoryDto";
import ArticleApi from "../../services/api/ArticleApi";


const Page = styled(PageWidth)`
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  gap:32px
`;

const FullGrayPageContainer = styled.div`
  display:flex;
  background: #F5F5F5
`;



const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const PopularTopics = styled.div`
  display:flex;
  flex-direction:column;
  padding: 16px;
  gap: 16px;
  border-radius: 10px;
  background: ${COLORS.neutral_50};
`;
const PopularTopicsHeading = styled.div`
  color: #1D1D1F;

  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 116.667% */
  letter-spacing: 0.216px;
`;


const LandingPage = () => {
  const { t } = useTranslation("landingPage");
  
  const [categoriesLoading, setCategoriesLoading] = useState<boolean>(true);
  const [categories, setCategories] = useState<ProductCategoryDto[]>([]);
  const [topicItems, setTopicItems] = useState<TopicItem[]>([]);

  useEffect(() => {
    ArticleApi
      .GetProductCategories()
      .then((data) => {
        setCategoriesLoading(false);
        setCategories(data);
        setTopicItems(data.map((x)=> ({
          text: x.name,
          asset: x.mainImageUrl,
          heightPx: "144px",
          link: `category/${x.linkName}`
        })))
      })
  }, []);

  return (
    <>
      <LandingHeader />

      <Page useMaxWidth={true} maxWidth={1024}>
        <PopularTopics>
          <PopularTopicsHeading>{t('Popular topics')}</PopularTopicsHeading>
          { categoriesLoading &&
            <LoadingIndicator />
          }
          { topicItems.length > 0 && 
            <Topics
              items={topicItems}
            />
          }
        </PopularTopics>
        <Subtopics
          items={[{
            icon: <ContentIcons.ProvidersOrders size="48" color={COLORS.primary_500} />,
            text: t('Order, Invoice and Shipment'),
            link: "https://laerdal.com/support/ordering-shipping/"
          }, {
            icon: <ContentIcons.PhysiologicalModel size="48" color={COLORS.primary_500} />,
            text: t('Spare parts Catalogue'),
            link: "https://laerdal.com/support/catalog/"
          }, {
            icon: <ContentIcons.Video size="48" color={COLORS.primary_500} />,
            text: t('How-to videos'),
            link: "https://laerdal.com/support/how-to-videos/"
          }, {
            icon: <ContentIcons.Stock size="48" color={COLORS.primary_500} />,
            text: t('Product returns'),
            link: "https://laerdal.com/us/support/ordering-shipping/product-returns/"
          }
          ]}
        />
        <Faqs
          title={t('Popular articles')}
          items={[{
            text: t('faq1'),
            link: "/articles/000001045"
          }, {
            text: t('faq2'),
            link: "/articles/000007470"
          }, {
            text: t('faq3'),
            link: "/articles/000004139"
          }, {
            text: t('faq4'),
            link: "/articles/000006325"
          }, {
            text: t('faq5'),
            link: "/articles/000005978"
          }
          ]}
        />

        <PageContainer>
          <ProductSupport />
          <KnowledgeHub />
          <LaerdalYoutube />
          <GlobalWarenty/>
          <LaerdalPrograms />
          <CantFindCreateCase />
        </PageContainer>
      </Page>
    </>);
};

export default LandingPage;