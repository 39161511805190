import { BREAKPOINTS, Button, Card, COLORS, ContentIcons, GlobalNavigationBar, Size } from '@laerdal/life-react-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';


const ProductSupportContainer = styled.div`
  ${BREAKPOINTS.LARGE}{
    flex-direction: row;
  }

  flex-direction: column-reverse;
  
  display: flex;
  
  justify-content: center;
  align-items: center;
  gap: 32px;
`;
const ProductSupportMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 0 32px;
`;
const ProductSupportTitle = styled.div`
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 45.6px */
`;
const ProductSupportSubtitle = styled.div`
  color: ${COLORS.neutral_600};
  font-feature-settings: 'liga' off;
  /* Components/Regular/XL */
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 116.667% */
`;
const ProductSupportText = styled.div`
  font-feature-settings: 'liga' off;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
`;
const ProductSupportIlustration = styled.div`
  ${BREAKPOINTS.LARGE}{
    flex-direction: row;
    padding: 0 32px;
  }

  margin:0 auto;
  align-self: stretch;

  display: flex;
  justify-content: center;
  align-items: center;

  
  img { 
    max-width: 100% !important;
    height: auto;
    width: auto;

  }
`;

const ProductSupport = () => {    
    const navigate = useNavigate()
    const { t } = useTranslation("landingPage");
  
    return (
        <ProductSupportContainer>
            <ProductSupportMain>
              <ProductSupportTitle>{t('Network Services Resources')}</ProductSupportTitle>
              {/* <ProductSupportSubtitle>Empowering everyday heroes</ProductSupportSubtitle> */}
              <ProductSupportText>{t('Here you will find resources to support the setup of a network optimized for your simulation training and equipment.')}</ProductSupportText>
              <Button 
                size={Size.Large} 
                variant="primary" 
                onClick={()=> window.open('https://laerdal.com/us/services-and-programs/technical-services/network-services/network-services-resources/#NSR-ValidatorTool', '_blank')}>
                  {t('Go to Network validator')}
              </Button>
            </ProductSupportMain>
            <ProductSupportIlustration>
              <img src="/assets/network-services-resources.png" alt={t('Network Services Resources')}></img>
            </ProductSupportIlustration>
          </ProductSupportContainer>
    );
}

export default ProductSupport;