import { ContentIcons, SystemIcons } from "@laerdal/life-react-components";

export const caseTypeSubtypes = [
    {
        value: 'technical',
        title: 'Technical Support and Troubleshooting', 
        subtitle: 'Let us know if you’re having trouble with a product or need help with technical issues.', 
        icon: <ContentIcons.Maintenance size="24px"/>,
        subtypes: [{
            value: 'productDoesNotWork',
            title: 'My product does not work',
         },{
            value: 'technicalSupport',
            title: 'I need technical support',
         },{
            value: 'assetMissing',
            title: 'Asset is missing or incorrect',
         },{
            value: 'guidance',
            title: 'I need some guidance'
         },{
            value: 'connect',
            title: 'Requesting access to the Laerdal Connect dashboard'
         },{
            varlue: 'simcapture',
            title: 'I need support with SimCapture'
         }]
    },
    {
        value: 'billing',
        title: 'Orders and Billing', 
        subtitle: 'Have a question about purchases, payments, or account details?', 
        icon: <ContentIcons.Note size="24px"/>,
        subtypes: [{
            value: 'orderOrInvoice',
            title: 'I have a question about an order or invoice',
         },{
            value: 'moreInformation',
            title: 'Request more information',
         },{
            value: 'qoute',
            title: 'I want to get a quote',
         },{
            value: 'updateAccount',
            title: 'I wish to update account or billing information',
         }]
    },
    {
        value: 'return',
        title: 'Return and Replacements', 
        subtitle: 'Need to return a product or request a replacement?', 
        icon: <ContentIcons.Stock size="24px"/>,
        subtypes: [{
            value: 'initiateReturn',
            title: 'I want to initiate a return',
         }]
    },
    {
        value: 'general',
        title: 'General Requests', 
        subtitle: 'Can’t find what you’re looking for? Reach out, and we’ll get back to you.', 
        icon: <ContentIcons.Information size="24px"/>,
        subtypes: [{
            value: 'questionAboutProduct',
            title: 'I have a question about a product or service',
         },{
            value: 'contactMe',
            title: 'I want to be contacted',
         }]
    },
    {
        value: 'privacy',
        title: 'Data Privacy and Security', 
        subtitle: 'Report any privacy, security, or vulnerability issue you’ve encountered with our products.', 
        icon: <ContentIcons.CloudLocked size="24px"/>,
        subtypes: [{
            value: 'privacyIssue',
            title: 'I have a data privacy issue or concern',
         },{
            value: 'securityIssue',
            title: 'I have a security issue or concern',
         },{
            value: 'dataBreach',
            title: 'I have identified a potential data breach',
         },{
            value: 'vulnerability',
            title: 'I have identified a vulnerability in a Laerdal-supplied software',
         },{
            value: 'patientSafety',
            title: 'I have a concern about patient safety or involvement',
         }]
    }
]