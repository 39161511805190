import { BREAKPOINTS, Card, COLORS, ComponentTextStyle, ComponentXL, ContentIcons, GlobalNavigationBar, HorizontalCard, SearchBar, Size, SystemIcons } from '@laerdal/life-react-components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import posthog from 'posthog-js';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  width: 100%;

  padding: 64px 0px;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  div:nth-child(2) {
    align-self: stretch;
  }
`;

const Title = styled.div`
  color: #000;

  text-align: center;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px; /* 115.789% */
`;


const CantFindWhatYouNeed = () => {    
  const { t } = useTranslation('translation');
  
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState<string>('');
  
  return (
    <Container>
      <Title>{t('Can’t find what you need?')}</Title>  
      <SearchBar
        id="DemoSearchBarSmall"
        placeholder={t('Type a question or search by keyword')}
        enterSearch={(e) => {
          
          posthog?.capture('SUPP-CantFindWhatYouNeed Search',{
            url: window.location.href,
            ref: document.referrer,
            searchTerm: searchTerm
          });
          navigate('/articles?query='+searchTerm);
        }}
        setSearchTerm={(term)=>{setSearchTerm(term)}}
        
        removeSearch={() => {
          
        }}
        size={Size.Small}
      />
    </Container>

  );
}

export default CantFindWhatYouNeed;