import { COLORS, ContentIcons, LoadingIndicator, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../commonComponents/Topics";
import Subtopics from "../../commonComponents/Subtopics";
import Faqs from "../../commonComponents/Faqs";
import CantFindCreateCase from "../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../commonComponents/ProductTopSection";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ProductCategoryDto } from "../../model/dto/productCategoryDto";
import ArticleApi from "../../services/api/ArticleApi";
import { ProductCategoryDetailsDto } from "../../model/dto/productCategoryDetailsDto";
import { useTranslation } from "react-i18next";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const ProductCategoryPage = () => {
    const { categoryLink } = useParams<{ categoryLink: string }>();
    const { t } = useTranslation("landingPage");

    const [categoryLoading, setCategoryLoading] = useState<boolean>(true);
    const [category, setCategory] = useState<ProductCategoryDetailsDto | undefined>(undefined);
      
    useEffect(() => {
        if(categoryLink)
        {
            ArticleApi
              .GetProductCategory(categoryLink)
              .then((data) => {
                  setCategoryLoading(false);
                  setCategory(data);
              })
        }
    }, [categoryLink]);

    
    return (
      <>
        { categoryLoading &&
            <LoadingIndicator></LoadingIndicator>
        }

        { category &&
            <>
                <ProductTopSection
                    title={category.name}
                    description={t(category.description)}
                    asset={category.mainImageUrl}
                    breadcrumb={[{
                        text: t('Home'),
                        link: '/'
                    },{
                        text: t(category.name)
                    }]}
                />

                <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
                    <PageContainer>
                        <Topics 
                            items={
                                category.products.map((x)=> ({
                                        text: x.name,
                                        asset: x.mainImageUrl,
                                        heightPx: "144px",
                                        link: `product/${x.link}`
                                    }))
                            }
                        />
                        
                        <Faqs 
                            title={t('FAQs')}
                            items={
                                category.faqs.map((x) => ({
                                        link: `/articles/Knowledge/${x.link}`,
                                        text: x.name
                                }))}                            
                            />
                        <CantFindWhatYouNeed />
                        <BigSmallContent 
                            big={{
                                title: t('Laerdal Global Warranty'),
                                description: t('globalWarrantyDescription'),
                                linkText: t('pdf'),
                                linkUrl: "https://cdn.laerdal.com/downloads/f4781/Att1toPRO-ML01-0295.pdf",
                            }}
                            small={{
                                title: t('Privacy Statement'),
                                description: t('privacyStatementDescription'),
                                linkText: t('readMore'),
                                linkUrl: "https://laerdal.com/support/customer-service/privacy-policy/"
                            }}/>
                        <CantFindCreateCase />
                    </PageContainer>
                </PageWidth>
            </>
        }
      </>);
  };
  
  export default ProductCategoryPage;