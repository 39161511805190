import { BackButton, BREAKPOINTS, Button, Checkbox, COLORS, ComponentM, ComponentS, ComponentTextStyle, ComponentXS, ContentIcons, DropdownFilter, HyperLink, IconButton, InputLabel, PageWidth, RadioButton, Size, SystemIcons, Textarea, TextField, ToastContext, TooltipWrapper } from "@laerdal/life-react-components";

import styled from "styled-components";
import { countryList } from "../../model/constants/CountryList";
import { useContext, useRef, useState, DragEvent, ChangeEvent, useEffect } from "react";
import { FailToastOptions, SuccessToastOptions } from "../../model/constants/ToastConstants";
import CaseApi from "../../services/api/CaseApi";
import LaerdalCustomerCare from "../../commonComponents/LaerdalCustomerCare";
import posthog from 'posthog-js';
import { useNavigate, useParams } from "react-router-dom";
import UrgentAssistance from "../../commonComponents/UrgentAssistance";
import { caseTypeSubtypes } from "../../model/constants/CaseTypeSubtype";
import { PageWidthFull } from "../../commonComponents/PageWidthFull";
import { useSelector } from 'react-redux';

import { useForm, Controller } from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from "react-i18next";
import { selectUserParams } from "../../store/slices/userSlice";


const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap:32px;
    display: flex;
    align-items: start;
    margin: 32px 0;    
`;

const CaseContent = styled.div`
    display: flex;
    max-width: 700px;
    flex-direction: column;
    align-items: center;
    gap: 21px;
    
    align-self: center;
    align-items: start;

    
    width: 100%;
`;
const Title = styled.div`
    color: var(--Black, #1F1F1F);
    font-feature-settings: 'liga' off;
    /* S - Mobile/H3 */
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 38.4px */
`;
const SubTitle = styled.div`
    color: ${COLORS.neutral_700}
    font-feature-settings: 'liga' off;
    /* M - Tablet/Paragraph */
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
`;


const Breadcrumb = styled.div`
  padding:12px 0;
`;
const CrumbLink = styled.a`
  &:link, &:visited, &:hover, &:active {
    color: ${COLORS.primary_600};
    text-decoration: none; 
  }

  color: ${COLORS.primary_600};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; 
`;
const CrumbText = styled.span`
  color: ${COLORS.neutral_500};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; 
`;
const Devider = styled.span`
  padding: 0 10px;
  color: ${COLORS.neutral_500};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; 
`;

const InputRow = styled.div`
    ${BREAKPOINTS.MEDIUM}{
        flex-direction: row;
    }
    flex-direction: column;

    display: flex;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
`;

const RadioRow = styled.div`
    flex-direction: row;
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: start;
    
`;

const InputCell = styled.div`
    width: 100%;
    align-self: stretch;
    display: flex;
    padding-bottom: 4px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex-grow: 1;

    div {
        width: 100%;
    }

    textarea {
        width: calc(100% - 32px) !important;
        min-height:100px;
    }
`;


const InputCell2 = styled.div`
    width: 100%;
    align-self: stretch;
    display: flex;
    padding-bottom: 4px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex-grow: 1;
    textarea {
        width: calc(100% - 32px) !important;
        min-height:100px;
    }
`;

const CreateButton = styled(Button)`
    ${BREAKPOINTS.MEDIUM}{
        align-self: end;
    }
    align-self: stretch;
`;


const ChoseFileContainerText = styled.div`
    ${BREAKPOINTS.MEDIUM}{
        display: flex;
    }
    display: none;
`
const ChoseFileContainer = styled.div`
    flex-grow: 1;
    display: flex;
    align-self: stretch;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    

    height: 152px;
    gap:21px;

    
    border-radius: 4px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none'  rx='4' ry='4' stroke='%23949494' stroke-width='2' stroke-dasharray='16%2c 16' stroke-dashoffset='12' stroke-linecap='square'/%3e%3c/svg%3e");
`;

const AttachmentsContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: start;
  gap: 16px;
  background-color: ${COLORS.neutral_20};
  padding: 24px 30px;
`;

const AttachmentDetails = styled.div`
  ${BREAKPOINTS.MEDIUM}{
    padding: 0px 10px;
  }
  padding: 5px 10px;

  background-color: ${COLORS.white};
  display:flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;
const AttachmentHeader = styled.div`
  display:flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;
const Tooltip = styled(TooltipWrapper)`
    ${BREAKPOINTS.MEDIUM}{
        width: max-content;
    }
    width: unset;
`;


const FullWidth = styled.div`
  border-top: 1px solid ${COLORS.neutral_200};
  background: ${COLORS.neutral_20};
`


const CaseForm = styled.form`
    display: flex;
    max-width: 700px;
    flex-direction: column;
    width: 100%;
    gap: 21px;
`

const Header = styled.div`
    margin: 0 0 46px 0;
`;

const CaseDropdownFilter = styled(DropdownFilter)`
    
    
`

const DropdownContainer = styled.div`
    & > div  {
        min-width: 200px;
    }

    & > div > div:first-child {
        height: 48px;
        min-width: 200px;
    }
`

const CaseFormPage = () => {
    const { option,suboption } = useParams<{ option: string, suboption: string}>();
    const { t } = useTranslation("cases");
    
    const schema = yup.object().shape({
        option: yup.string(),
        suboption: yup.string(),
        firstName: yup.string().required(t('Required')),
        lastName: yup.string().required(t('Required')),
        workEmail: yup.string().email(t('Not an email')).required(t('Required')),
        telephone: yup.string().required(t('Required')),
        organization: yup.string().required(t('Required')),
        country: yup.string().required(t('Required')),
        stateOrProvince: yup.string(),
        zipCode: yup.string(),
        city: yup.string(),
        productName:yup.string(),
        assistanceWith:yup.string(),
        description: yup.string().required(t('Required')),
        contactMethod: yup.string(),
        orderOrPoNumber: yup.string(),
        accountNumber: yup.string(),
        productSerial: yup.string(),
        reasonForReturn: yup.string(),
        organisationType: yup.string(),
        role: yup.string(),
        privacyStatement: yup.boolean(),
    });
    
    const navigate = useNavigate()
    const { addToast } = useContext(ToastContext);
    const userData = useSelector(selectUserParams);

    
    const caseOption = caseTypeSubtypes.find((type) => type.value === option);
    const caseSubOption = caseOption?.subtypes.find((subtype) => subtype.value === suboption);
    
    const [creatingcase, setCreatingcase] = useState<boolean>(false);
    const [privacyStatement, setPrivacyStatement] = useState<boolean>(false);

    const [fileSizeOverLimit, setFileSizeOverLimit] = useState<boolean>(false);

    const [files, setFiles] = useState<File[]>([]);
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const MAX_FILE_SIZE = 30 * 1024 * 1024;

    const allowedTypes = [
        'image/jpeg', 'image/png', 'image/gif', 'image/webp',
        'video/mp4', 'video/webm', 'video/ogg',
        'application/pdf', 'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
    ];

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            option: option,
            suboption: suboption,

            description: '',
            firstName: userData?.firstName || '',
            lastName: userData?.lastName || '',
            workEmail: userData?.email || '',
            telephone: '',
            contactMethod: '',
            country: userData?.countryCode2 || '',
            stateOrProvince: '',
            zipCode: '',
            city: '', 
            assistanceWith: '',
            productName: '',
            accountNumber: '',
            reasonForReturn: '',
            organisationType: '',
            role: '',
            privacyStatement: true,
            organization: userData?.accountName || '',
            orderOrPoNumber: '',
            productSerial: '',
        }
    });

    const onSubmit = (data: any) => {
        if (creatingcase) return;

        setCreatingcase(true);
        const caseDto = {
            customerNumber: userData.accountNumber,
            country: data.country,
            howCanWeHelp: 'Other',
            email: data.workEmail,
            subject: 'Support page - Request',
            description: `
                First name: ${data.firstName}
                Last name: ${data.lastName}
                Organization: ${data.organization}
                Country: ${data.country}
                Telephone: ${data.telephone}
                ${ caseOption ? `Option: ${caseOption.title}` : ''}
                ${ caseSubOption ? `Suboption: ${caseSubOption.title}` : ''}
                ${ data.contactMethod ? `Contact method: ${data.contactMethod}` : ''}
                ${ data.orderOrPoNumber ? `Order and/or PO Numbe: ${data.orderOrPoNumber}` : ''}
                ${ data.productSerial ? `Product Serial Number: ${data.productSerial}` : ''}
                ${ data.stateOrProvince ? `State or province: ${data.stateOrProvince}` : ''}
                ${ data.zipCode ? `Zip code: ${data.zipCode}` : ''}
                ${ data.assistanceWith ? `Assistance with: ${data.assistanceWith}` : ''}
                ${ data.productName ? `Product name: ${data.productName}` : ''}
                ${ data.accountNumber ? `Product name: ${data.accountNumber}` : ''}
                ${ data.reasonForReturn ? `Reason for return: ${data.reasonForReturn}` : ''}
                ${ data.organisationType ? `Organisation type: ${data.organisationType}` : ''}
                ${ data.role ? `Role: ${data.role}` : ''}

                Description: ${data.description}
            `
        }
        caseDto.description = caseDto.description.replace(/^\s*$(?:\r\n?|\n)/gm, '');
        CaseApi.CreateCase(caseDto, files).then((caseNumber) => {
            let posthogObject = {
                caseNumber: caseNumber,
                firstName: data.firstName,
                lastName: data.lastName,
                organization: data.organization,
                country: data.country,
                telephone: data.telephone,
                contactMethod: data.contactMethod,
                orderOrPoNumber: data.orderOrPoNumber,
                productSerial: data.productSerial,
                description: data.description,
                stateOrProvince: data.stateOrProvince,
                zipCode: data.zipCode,
                assistanceWith: data.assistanceWith,
                productName: data.productName,
                accountNumber: data.accountNumber,
                reasonForReturn: data.reasonForReturn,
                organisationType: data.organisationType,
                role: data.role,
                files: files.map(file => file.name),
                caseDto: caseDto
            }
            posthog?.capture?.('CreateCaseModal Created', 
                posthogObject
            );

            posthog?.capture?.('SUPP-CreateCase Created', 
                posthogObject
            );

            navigate(`/case/create/confirmation/${caseNumber}`);
        }).catch((ex) => {
            if (ex?.response?.data?.errors['']?.some((error: string) => error?.includes('Request body too large'))) {
                posthog?.capture?.('CreateCaseModal documents too large', caseDto);
                addToast(t('The documents are too large! Maximum size of all documents must be under 30MB'), FailToastOptions);
            }
            else {
                posthog?.capture?.('CreateCaseModal Error', caseDto);
                addToast(t('There was a problem creating the case'), FailToastOptions);
            }
            setCreatingcase(false);
        })
    };

    const handleFiles = (newFiles: FileList | null) => {
        console.log('handleFiles', newFiles);
        if (newFiles) {
            setFiles(prevFiles => [...prevFiles, ...Array.from(newFiles)]);
            if (fileInputRef.current) {
                fileInputRef.current.value = ""; // Clear the current file input
            }
        }
    }
    const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        const droppedFiles = e.dataTransfer.files;
        handleFiles(droppedFiles);
    };

    const handleClick = () => {
        console.log('handleClick');
        fileInputRef.current?.click();
    };
    const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        console.log('handleButtonClick');
        fileInputRef.current?.click();
    };

    const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleFiles(e.target.files);
    };

    const handleRemoveFile = (index: number) => {
        setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    };

    useEffect(() => {
        const totalSize = files.reduce((sum, file) => sum + file.size, 0);
        setFileSizeOverLimit(totalSize > MAX_FILE_SIZE);
    }, [files]);

    return (
        <>
            <PageWidthFull useMaxWidth={true} maxWidth={1024} unsetMargin={true}>
                <PageContainer>
                    <Header>
                        <BackButton size={Size.Small} onClick={() => window.history.back()}>{t('Back')} </BackButton>
                        <Title>{t('Can you give us more details?')}</Title>
                        <SubTitle>{t('Please provide more information so our team can assist you better')} </SubTitle>
                    </Header>

                    <CaseContent>
                        <CaseForm onSubmit={handleSubmit(onSubmit)}>
                            <InputRow>
                                <InputCell>
                                    <InputLabel inputId="FirstName" text={t('First name')} size={Size.Small} required={true} />
                                    <Controller
                                        name="firstName"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                id="FirstName"
                                                data-testId="FirstName"
                                                validationMessage={errors.firstName?.message}
                                                placeholder={t('e.g. Annie')}
                                                size={Size.Small}
                                            />
                                        )}
                                    />
                                </InputCell>

                                <InputCell>
                                    <InputLabel inputId="LastName" text={t('Last name')} size={Size.Small} required={true} />
                                    <Controller
                                        name="lastName"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                id="LastName"
                                                data-testId="LastName"
                                                validationMessage={errors.lastName?.message}
                                                placeholder={t('e.g. Laerdal')}
                                                size={Size.Small}
                                            />
                                        )}
                                    />
                                </InputCell>
                            </InputRow>

                            <InputRow>
                                <InputCell>
                                    <InputLabel inputId="WorkEmail" text={t('Work email')} size={Size.Small} required={true} />
                                    <Controller
                                        name="workEmail"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                id="WorkEmail"
                                                data-testId="WorkEmail"
                                                validationMessage={errors.workEmail?.message}
                                                placeholder={t('e.g. annie.laerdal@laerdal.com')}
                                                size={Size.Small}
                                            />
                                        )}
                                    />
                                </InputCell>

                                <InputCell>
                                    <InputLabel inputId="Telephone" text={t('Telephone')}  size={Size.Small} required={true} />
                                    <Controller
                                        name="telephone"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                id="Telephone"
                                                data-testId="Telephone"
                                                validationMessage={errors.telephone?.message}
                                                placeholder={t('e.g. 987654321')} 
                                                size={Size.Small}
                                            />
                                        )}
                                    />
                                </InputCell>
                            </InputRow>

                            <InputRow>
                                <InputCell>
                                    <InputLabel inputId="Organization" text={t('Organization')} size={Size.Small} required={true} />
                                    <Controller
                                        name="organization"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                id="Organization"
                                                data-testId="Organization"
                                                validationMessage={errors.organization?.message}
                                                placeholder={t('e.g. Laerdal Medical')} 
                                                size={Size.Small}
                                            />
                                        )}
                                    />
                                </InputCell>

                                <InputCell2>
                                    <InputLabel inputId="ContactMethod" text={t('Preferred contact method')} size={Size.Small} />
                                    <Controller
                                        name="contactMethod"
                                        control={control}
                                        render={({ field }) => (
                                            <RadioRow>
                                                <RadioButton
                                                    {...field}
                                                    size={Size.Small}
                                                    label={t('Work email')} 
                                                    selected={field.value === 'email'}
                                                    select={() => field.onChange('email')}
                                                />
                                                <RadioButton
                                                    {...field}
                                                    label={t('Telephone')}
                                                    selected={field.value === 'phone'}
                                                    select={() => field.onChange('phone')}
                                                />
                                            </RadioRow>
                                        )}
                                    />
                                </InputCell2>
                            </InputRow>

                            <InputRow>
                                <InputCell>
                                    <InputLabel inputId="OrderOrPoNumber" text={t('Enter the Order and/or PO Number')} size={Size.Small} />
                                    <Controller
                                        name="orderOrPoNumber"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                id="OrderOrPoNumber"
                                                data-testId="OrderOrPoNumber"
                                                placeholder={t('e.g. P3254434')} 
                                                size={Size.Small}
                                            />
                                        )}
                                    />
                                </InputCell>
                                <InputCell>
                                    <InputLabel inputId="ProductSerial" text={t('Enter product serial number(s)')}  size={Size.Small} />
                                    <Controller
                                        name="productSerial"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                id="ProductSerial"
                                                data-testId="ProductSerial"
                                                placeholder={t('e.g. TSBH02010077')} 
                                                size={Size.Small}
                                            />
                                        )}
                                    />
                                </InputCell>
                            </InputRow>

                            <InputRow>
                                <InputCell>
                                    <InputLabel inputId="Country" text={t('Country')} size={Size.Small} required={true} />
                                    <DropdownContainer>
                                        <Controller
                                            name="country"
                                            control={control}
                                            render={({ field }) => (
                                                <CaseDropdownFilter
                                                    {...field}
                                                    activeValidationMessage={errors.country?.message}
                                                    scrollable={true}
                                                    id="Country"
                                                    dataTestId='CountryId'
                                                    list={countryList}
                                                    placeholder={t('-- Select an option --')}
                                                    onSelect={(value) => field.onChange(value)}
                                                    messageOnNoResults={t('No results found')}
                                                    size={Size.Medium}
                                                />
                                            )}
                                        />
                                    </DropdownContainer>
                                </InputCell>
                                <InputCell>
                                    <InputLabel inputId="stateOrProvince" text={t('State or Province')} size={Size.Small} />
                                    <Controller
                                        name="stateOrProvince"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                id="stateOrProvince"
                                                data-testId="stateOrProvince"
                                                size={Size.Small}
                                            />
                                        )}
                                    />
                                </InputCell>
                            </InputRow>


                            <InputRow>
                                <InputCell>
                                    <InputLabel inputId="organisationType" text={t('Organisation type')} size={Size.Small} required={true} />
                                    <DropdownContainer>
                                        <Controller
                                            name="organisationType"
                                            control={control}
                                            render={({ field }) => (
                                                <CaseDropdownFilter
                                                    {...field}
                                                    activeValidationMessage={errors.organisationType?.message}
                                                    scrollable={true}
                                                    id="organisationType"
                                                    dataTestId='organisationType'
                                                    list={[
                                                        {displayLabel: t('Educational Provider'), value: 'Educational Provider'},
                                                        {displayLabel: t('Healthcare Provider'), value: 'Healthcare Provider'},
                                                        {displayLabel: t('Emergency Medical Service'), value: 'Emergency Medical Service'},
                                                        {displayLabel: t('Government / Military'), value: 'Government / Military'},
                                                        {displayLabel: t('Other'), value: 'Other'}
                                                    ]}
                                                    placeholder={t('-- Select an option --')}
                                                    onSelect={(value) => field.onChange(value)}
                                                    messageOnNoResults={t('No results found')}
                                                    size={Size.Medium}
                                                />
                                            )}
                                        />
                                    </DropdownContainer>
                                </InputCell>
                                <InputCell>
                                    <InputLabel inputId="role" text={t('Role')} size={Size.Small} />
                                    <DropdownContainer>
                                        <Controller
                                            name="role"
                                            control={control}
                                            render={({ field }) => (
                                                <CaseDropdownFilter
                                                    {...field}
                                                    activeValidationMessage={errors.organisationType?.message}
                                                    scrollable={true}
                                                    id="role"
                                                    dataTestId='role'
                                                    list={[
                                                        {displayLabel: t('Educator'), value: 'Educator'},
                                                        {displayLabel: t('Purchaser'), value: 'Purchaser'},
                                                        {displayLabel: t('Administrator'), value: 'Administrator'},
                                                        {displayLabel: t('Technician'), value: 'Technician'},
                                                        {displayLabel: t('Manager'), value: 'Manager'}
                                                    ]}
                                                    placeholder={t('-- Select an option --')}
                                                    onSelect={(value) => field.onChange(value)}
                                                    messageOnNoResults={t('No results found')}
                                                    size={Size.Medium}
                                                />
                                            )}
                                        />
                                    </DropdownContainer>
                                </InputCell>
                            </InputRow>

                            
                            <InputRow>
                                <InputCell>
                                    <InputLabel inputId="zipCode" text={t('Zip code')} size={Size.Small} />
                                    <Controller
                                        name="zipCode"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                id="zipCode"
                                                data-testId="zipCode"
                                                size={Size.Small}
                                            />
                                        )}
                                    />
                                </InputCell>
                                <InputCell>
                                    <InputLabel inputId="city" text={t('City')}  size={Size.Small} />
                                    <Controller
                                        name="city"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                id="city"
                                                data-testId="city"
                                                size={Size.Small}
                                            />
                                        )}
                                    />
                                </InputCell>
                            </InputRow>

                            <InputRow>
                                <InputCell>
                                    <InputLabel inputId="accountNumber" text={t('Account number')} size={Size.Small} />
                                    <Controller
                                        name="accountNumber"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                id="accountNumber"
                                                data-testId="accountNumber"
                                                size={Size.Small}
                                            />
                                        )}
                                    />
                                </InputCell>
                                <InputCell>
                                    <InputLabel inputId="productName" text={t('Product name')}  size={Size.Small} />
                                    <Controller
                                        name="productName"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                id="productName"
                                                data-testId="productName"
                                                size={Size.Small}
                                            />
                                        )}
                                    />
                                </InputCell>
                            </InputRow>

                            <InputRow>
                                <InputCell>
                                    <InputLabel inputId="reasonForReturn" text={t('Reason for return')} size={Size.Small} />
                                    <Controller
                                        name="reasonForReturn"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                id="reasonForReturn"
                                                data-testId="reasonForReturn"
                                                size={Size.Small}
                                            />
                                        )}
                                    />
                                </InputCell>
                            </InputRow>

                            <InputRow>
                                <InputCell>
                                    <InputLabel inputId="Description" text={t('Description')} size={Size.Small} required={true} />
                                    <Controller
                                        name="description"
                                        control={control}
                                        render={({ field }) => (
                                            <Textarea
                                                {...field}
                                                id="Description"
                                                size={Size.Medium}
                                                data-testId="Description"
                                                validationMessage={errors.description?.message}
                                                placeholder={t('Description')}
                                            />
                                        )}
                                    />
                                </InputCell>
                            </InputRow>

                            <InputRow>
                                <InputCell>
                                    <InputLabel inputId="assistanceWith" text={t('Please describe what you need assistance with')} size={Size.Small} required={true} />
                                    <Controller
                                        name="assistanceWith"
                                        control={control}
                                        render={({ field }) => (
                                            <Textarea
                                                {...field}
                                                id="assistanceWith"
                                                size={Size.Medium}
                                                data-testId="assistanceWith"
                                                validationMessage={errors.description?.message}
                                                placeholder={t('Please describe what you need assistance with')}
                                            />
                                        )}
                                    />
                                </InputCell>
                            </InputRow>

                            <InputRow>
                                <ChoseFileContainer
                                    onDragEnter={handleDragEnter}
                                    onDragLeave={handleDragLeave}
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                    onClick={handleClick}>
                                    <ChoseFileContainerText>
                                        <ComponentM textStyle={ComponentTextStyle.Bold} color={COLORS.neutral_500}>{t('Drag files here to upload them, or')}</ComponentM>
                                    </ChoseFileContainerText>
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        onChange={handleFileInputChange}
                                        multiple
                                        accept={allowedTypes.join(',')}
                                        style={{ display: 'none' }}
                                    />

                                    <Button icon={<SystemIcons.Upload />} onClick={handleButtonClick} variant="secondary">{t('Choose File')}</Button>
                                </ChoseFileContainer>
                            </InputRow>

                            <InputRow>
                                {files.length > 0 && (
                                    <AttachmentsContainer>
                                        <AttachmentHeader>
                                            <ComponentXS textStyle={ComponentTextStyle.Bold}>{t('ATTACHMENTS')} </ComponentXS>
                                            <Tooltip label={t('These attachments can be seen and downloaded by Laerdal Support')} position="top" delay="0">
                                                <SystemIcons.Information />
                                            </Tooltip>
                                        </AttachmentHeader>
                                        {fileSizeOverLimit &&
                                            <ComponentM color={COLORS.critical_500}>
                                                {t('Files too large! Maximum is 30MB')}
                                            </ComponentM>
                                        }

                                        <>
                                            {files.map((file, index) => (
                                                <AttachmentDetails key={index}>
                                                    <IconButton variant="secondary" action={() => handleRemoveFile(index)}>
                                                        <SystemIcons.Close color={COLORS.neutral_500} />
                                                    </IconButton>

                                                    <ComponentS color={COLORS.neutral_500} >{file.name}</ComponentS>
                                                </AttachmentDetails>
                                            ))}
                                        </>
                                    </AttachmentsContainer>
                                )}
                            </InputRow>

                            <InputRow>
                                
                                    <Checkbox  
                                        label={t('By checking this box, I acknowledge that I have read and understood the Laerdal Privacy Statement provided below. I consent to the storage, sharing, and processing of my personal information, including my name, phone number, and email address, which I have provided on this form, for the purposes outlined in the Laerdal Privacy Statement.')}
                                        selected={privacyStatement}
                                        select={(s) => setPrivacyStatement(s)}>
                                            
                                        </Checkbox>
                                
                            </InputRow>

                            <CreateButton
                                size={Size.Large}
                                disabled={fileSizeOverLimit || !privacyStatement}
                                loading={creatingcase}
                                type="submit">
                                {t('Submit')}
                            </CreateButton>
                            

                            <InputRow>
                                <ComponentS>
                                    {t('We will handle your personal contact details with care as outlined in')}
                                    &nbsp;
                                    <HyperLink variant="default" href="https://laerdal.com/support/customer-service/contact-us/" target="_blank">{t("Laerdal's Privacy Policy.")}</HyperLink> 
                                </ComponentS>
                            </InputRow>
                        </CaseForm>
                    </CaseContent>
                </PageContainer>
            </PageWidthFull>
            <FullWidth>
                <PageWidth useMaxWidth={true} maxWidth={1024}>
                    <UrgentAssistance />
                </PageWidth>
            </FullWidth>
        </>
    );
};

export default CaseFormPage;