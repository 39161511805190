import { BREAKPOINTS, Card, COLORS, ContentIcons, GlobalNavigationBar } from '@laerdal/life-react-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';


const ProgramsContainer = styled.div`

  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`;
const ProgramsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;
const ProgramsTitle = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; 
`;
const ProgramsLink = styled.a`
  &:link, &:visited, &:hover, &:active {
    color: ${COLORS.primary_600};
    text-decoration: none; 
  }

  color: ${COLORS.primary_600};
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; 
`;

const LaerdalPrograms = () => {    
    const navigate = useNavigate()
    const { t } = useTranslation("landingPage");
  
    return (
        <ProgramsContainer>
            <ProgramsList>
              <ProgramsTitle>{t('Laerdal Programs')}</ProgramsTitle>
              <ProgramsLink href="https://laerdal.com/services-and-programs/educational-services/">
                {t('Educational Services with over 160 simulation experts in 22 countries who are ready to help.')}
              </ProgramsLink>
              <ProgramsLink href="https://laerdal.com/services-and-programs/technical-services/">
                {t('Technical Services  to meet your specific product and training needs.')}
              </ProgramsLink>
              <ProgramsLink href="https://laerdal.com/services-and-programs/managed-services-program/">
                {t('Managed Services Program for implementing or sustaining an effective simulation training program.')}
              </ProgramsLink>
              <ProgramsLink href="https://laerdal.com/products/courses-learning/virtual-simulation/vsim-for-nursing/">
                {t('Virtual Simulation for practice from anywhere with vSim for Nursing.')}
              </ProgramsLink>
              <ProgramsLink href="https://laerdal.com/services-and-programs/resuscitation-quality-improvement/">
                {t('The Resuscitation Quality Improvement (RQI) programs help meet BLS quality and compliance goals.')}
              </ProgramsLink>
            </ProgramsList>
          </ProgramsContainer>
    );
}

export default LaerdalPrograms;