import { BackButton, BREAKPOINTS, Button, COLORS, ComponentL, ComponentTextStyle, ContentIcons, HorizontalCard, HyperLink, LoadingIndicator, PageWidth, Size, SystemIcons } from "@laerdal/life-react-components";

import styled from "styled-components";
import LaerdalCustomerCare from "../../commonComponents/LaerdalCustomerCare";
import UrgentAssistance from "../../commonComponents/UrgentAssistance";
import { caseTypeSubtypes } from "../../model/constants/CaseTypeSubtype";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { KnowledgeArticleListItemDto } from "../../model/dto/knowledgeArticleListItemDto";
import ArticleApi from "../../services/api/ArticleApi";
import Faqs from "../../commonComponents/Faqs";
import { useLocation } from "react-router-dom";
import React from "react";
import { useDispatch } from 'react-redux';
import { saveUserData } from '../../store/slices/userSlice';
import { useTranslation } from "react-i18next";


const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 32px 0;
`;
const Header = styled.div`
    margin: 0 0 46px 0;
`;

const H3 = styled.div`
    color: var(--Base-Black, #1F1F1F);
    /* Content/H3 */
    font-family: var(--font-family-Title, Lato);
    font-size: var(--font-size-H3, 40px);
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 48px */
`;

const FullWidth = styled.div`
  border-top: 1px solid ${COLORS.neutral_200};
  background: ${COLORS.neutral_20};
`

const Options = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const Side = styled.div`
    display: flex;
    flex-direction: column;
    gap:16px;
`

const Row = styled.div`
    display: flex;
    ${BREAKPOINTS.MEDIUM}{
        flex-direction: row;
        gap:64px
    }

    flex-direction: column;
    gap:8px
`

const Link = styled(HyperLink)`
    border-radius: 4px;
    border: 1px solid var(--Neutral-200-tint, #CCC);
    background: var(--Base-White, #FFF);
    padding: 12px 20px 7px 20px;

    svg {
        vertical-align: middle;
        margin: 0 8px 4px 0;
    }
`;

const CanNotSpecifyBtn = styled(Button)`
    align-self: start;
    margin: 8px 0;
`;

const UsfulArticles = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 64px;
`;

const CaseCreatePage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation("cases");

    const [articles, setArticles] = useState<KnowledgeArticleListItemDto[]>([]);
    const [articlesLoading, setArticlesLoading] = useState<boolean>(true);
    
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        
        const firstName = getParam(urlParams.get("firstName"));
        const lastName = getParam(urlParams.get("lastName"));
        const email = getParam(urlParams.get("email"));
        const accountNumber = getParam(urlParams.get("accountNumber"));
        const accountName = getParam(urlParams.get("accountName"));
        const country = getParam(urlParams.get("country"));
        const countryCode2 = getParam(urlParams.get("countryCode2"));

        dispatch(saveUserData({
            firstName,
            lastName,
            email,
            accountNumber,
            accountName,
            country,
            countryCode2
        }));

        function getParam(paramValue: string | null) {
            return paramValue == "null" ? null : paramValue;
        }
    }, []);

    useEffect(() => {
        ArticleApi.GetKnowledgeArticles(4, 0, 'technical support', '')
            .then((data) => {
            setArticles(data.items);
            setArticlesLoading(false);
        });
    }, []);
        
    return (
        <>
            <PageWidth useMaxWidth={true} maxWidth={1024} unsetMargin={true}>
                
                <PageContainer>
                    <Header>
                        <BackButton size={Size.Small} onClick={() => window.history.back()}  >
                            {t('Back')} 
                        </BackButton>
                        <H3>{t('How can we assist you today?')}</H3>
                        <ComponentL color={COLORS.neutral_600}>{t('Choose an option below to get started.')}</ComponentL>
                    </Header>

                    <Row>
                        <Options>
                            {caseTypeSubtypes.map((caseType, index) => (
                                <HorizontalCard 
                                    key={index}
                                    icon={caseType.icon}
                                    title={t(caseType.title)}
                                    description={t(caseType.subtitle)}
                                    action={() => {
                                        if(caseType.subtypes.length > 1)
                                            navigate(`/case/create/${caseType.value}`)
                                        else
                                            navigate(`/case/create/${caseType.value}/${caseType.subtypes[0].value}`)
                                    }}
                                />
                            ))}
                            <CanNotSpecifyBtn variant="tertiary" onClick={() => navigate(`/case/create/unknown/unknown`)}>{t('Can not specify?')}</CanNotSpecifyBtn>
                        </Options>
                        <Side>
                            <Link variant="default" href="https://laerdal.com/support/catalog/" ><SystemIcons.ShoppingCart size="24px"/>{t('Shop for parts, and accessories')}</Link>
                            <Link variant="default" href="https://laerdal.com/support/scheduled-support/" ><SystemIcons.Educator size="24px"/>{t('Schedule a call with technician')}</Link>
                        </Side>
                    </Row>
                </PageContainer>
            </PageWidth>
            <FullWidth>
                <PageWidth useMaxWidth={true} maxWidth={1024}>
                    { articlesLoading && <LoadingIndicator/>}
                    { articles.length > 0 && 
                        <UsfulArticles>
                            <ComponentL textStyle={ComponentTextStyle.Bold}>{t('Useful articles')}</ComponentL>
                            <Faqs
                                items={
                                    articles.map((x)=> ({
                                        text: x.title,
                                        link: `/articles/${x.articleNumber}`
                                    }))
                                } />
                        </UsfulArticles>
                    }
                    
                    <UrgentAssistance />
                </PageWidth>
            </FullWidth>
        </>);
};

export default CaseCreatePage;