import { LoadingIndicator, SiteFooter, ToastProvider } from '@laerdal/life-react-components';
import { Component, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Route, RouterProvider, useLocation, useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import i18next from 'i18next';
import { initReactI18next, I18nextProvider, useTranslation } from 'react-i18next';
import ArticlePage from './pages/articlePage/ArticlePage';
import ArticleSearchPage from './pages/articleSearchPage/ArticleSearchPage';
import LandingPage from './pages/landingPage/LandingPage';
import EmbededSearchPage from './pages/embededSearchPage/EmbededSearchPage';
import EmbededArticlePage from './pages/embededArticlePage/EmbededArticlePage';
import { createBrowserRouter, Routes, ScrollRestoration } from 'react-router-dom';
import Header from './commonComponents/Header';
import posthog from 'posthog-js';
import CaseCreatePage from './pages/cases/CaseCreatePage';
import PartFinderPage from './pages/partFinderPage/PartFinderSearchPage';
import PartFinderSearchPage from './pages/partFinderPage/PartFinderSearchPage';
import PartFinderItemPage from './pages/partFinderPage/PartFinderItemPage';
import ProductCategoryPage from './pages/landingPage/ProductCategoryPage';
import ProductLinePage from './pages/landingPage/ProductLinePage';
import ProductLineComponent from './pages/landingPage/ProductLineComponent';
import CaseSuboptionsPage from './pages/cases/CaseSuboptionsPage';
import CaseFormPage from './pages/cases/CaseFormPage';
import CaseConfirmationPage from './pages/cases/CaseConfirmationPage';
import ProductLineArticlesPage from './pages/landingPage/ProductLineArticlesPage';
import backend from "i18next-http-backend";
import React from 'react';
import LanguageSelector from './commonComponents/Language';
import { useDispatch, useSelector } from 'react-redux';
import { setMarket, setLocale, selectMarket, selectLocale } from './store/slices/globalConfigSlice';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const FooterContainer = styled.div`
  margin-top: auto;
`;

const TestIframe = styled.iframe`
  height: 95vh;
`;

const LoadingContainer = styled.div`
  padding: 50px;
`;


// Initialize i18next
i18next
  .use(backend)
  .use(initReactI18next)
  .init({
    lng: 'en',
    debug: false,
    load: 'currentOnly',
    cleanCode: true,
    keySeparator: false,
    fallbackLng: 'en',
    compatibilityJSON: 'v3',
    defaultNS: 'Common',
    interpolation: {
      escapeValue: false,
      format: function (value: any, format: any, lng: any) {
        // if (value instanceof Date) {
        //   return moment(value).format(format);
        // }
        return value;
      },
    },
    react: {
      useSuspense: true,
    },
    nonExplicitSupportedLngs:false,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    }
  });

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const currentLocale = useSelector(selectLocale);
  const currentMartket = useSelector(selectMarket);
  const navigate = useNavigate();
  const { t } = useTranslation( ['translation', 'cases', 'landingPage']);

  useEffect(() => {
    i18next.changeLanguage(currentLocale);
  }, [currentLocale]);

  window.addEventListener("message",
    (event) => {
      if(event.data == 'closingHelpU'){
        // @ts-ignore
        posthog?.capture('helpU closed',{
          $geoip_disable: true,
        });
      }
    },
    false
  );

  const handlePageChange = () => {
    // @ts-ignore
    let timeSpent = Math.floor((Date.now() - window.supportStartTime) / 1000);
    posthog.capture('SUPP-PageLeave',
      {
        seconds_on_page: timeSpent, 
        //@ts-ignore
        url: window.supportFullUrl
      }
    );

    
    // @ts-ignore
    window.supportStartTime = new Date().getTime();
    // @ts-ignore
    window.supportFullUrl = window.location.href;
  };

  useEffect(() => { 
    if(location.pathname.toLowerCase().startsWith('/la')) 
      dispatch(setMarket('la'));
    else if(location.pathname.toLowerCase().startsWith('/br')) 
      dispatch(setMarket('br'));
    else if(location.pathname.toLowerCase().startsWith('/ca')) 
      dispatch(setMarket('ca'));
    else if(location.pathname.toLowerCase().startsWith('/mx')) 
      dispatch(setMarket('mx'));
    else if(location.pathname.toLowerCase().startsWith('/us')) 
      dispatch(setMarket('us'));
    else if(location.pathname.toLowerCase().startsWith('/au')) 
      dispatch(setMarket('au'));
    else if(location.pathname.toLowerCase().startsWith('/hk')) 
      dispatch(setMarket('hk'));
    else if(location.pathname.toLowerCase().startsWith('/in')) 
      dispatch(setMarket('in'));
    else if(location.pathname.toLowerCase().startsWith('/jp')) 
      dispatch(setMarket('jp'));
    else if(location.pathname.toLowerCase().startsWith('/kr')) 
      dispatch(setMarket('kr'));
    else if(location.pathname.toLowerCase().startsWith('/my')) 
      dispatch(setMarket('my'));
    else if(location.pathname.toLowerCase().startsWith('/nz')) 
      dispatch(setMarket('nz'));
    else if(location.pathname.toLowerCase().startsWith('/sg')) 
      dispatch(setMarket('sg'));
    else if(location.pathname.toLowerCase().startsWith('/be-nl')) 
      dispatch(setMarket('be-nl'));
    else if(location.pathname.toLowerCase().startsWith('/be-fr')) 
      dispatch(setMarket('be-fr'));
    else if(location.pathname.toLowerCase().startsWith('/dk')) 
      dispatch(setMarket('dk'));
    else if(location.pathname.toLowerCase().startsWith('/de')) 
      dispatch(setMarket('de'));
    else if(location.pathname.toLowerCase().startsWith('/es')) 
      dispatch(setMarket('es'));
    else if(location.pathname.toLowerCase().startsWith('/fr')) 
      dispatch(setMarket('fr'));
    else if(location.pathname.toLowerCase().startsWith('/it')) 
      dispatch(setMarket('it'));
    else if(location.pathname.toLowerCase().startsWith('/nl')) 
      dispatch(setMarket('nl'));
    else if(location.pathname.toLowerCase().startsWith('/no')) 
      dispatch(setMarket('no'));
    else if(location.pathname.toLowerCase().startsWith('/pl')) 
      dispatch(setMarket('pl'));
    else if(location.pathname.toLowerCase().startsWith('/cn')) 
      dispatch(setMarket('cn'));
    else if(location.pathname.toLowerCase().startsWith('/fi')) 
      dispatch(setMarket('fi'));
    else if(location.pathname.toLowerCase().startsWith('/se')) 
      dispatch(setMarket('se'));
    else if(location.pathname.toLowerCase().startsWith('/gb')) 
      dispatch(setMarket('gb'));
    else
      dispatch(setMarket(''));
    
  }, []);

  useEffect(() => { 
    if(!!currentMartket){
      if(location.pathname.startsWith(`/${currentMartket}`) == false){
        navigate(`/${currentMartket}${location.pathname}`, { replace: true });
      }
    }
  }, [location.pathname]);

  useEffect(() => { 
    posthog.capture('$pageview')
    handlePageChange();
  }, [location.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    window.addEventListener('beforeunload', handlePageChange);

    return () => {
      window.removeEventListener('beforeunload', handlePageChange);
    };
  }, []);

  return (
    <React.Suspense fallback={<LoadingContainer><LoadingIndicator/></LoadingContainer>}>
      <ContentContainer>
        {location.pathname.startsWith('/embeded') == false &&
          <>
            <LanguageSelector/>
            <Header/>
          </>
        }
  
        <Routes>
          <Route path='/:market?/' element={<LandingPage/>} />
          <Route path='/:market?/category/:categoryLink' element={<ProductCategoryPage/>} />
          <Route path='/:market?/category/:categoryLink/product/:productLink' element={<ProductLinePage/>} />
          <Route path='/:market?/category/:categoryLink/product/:productLink/component/:componentLink' element={<ProductLinePage/>} />
          <Route path='/:market?/category/:categoryLink/product/:productLink/articles/:componentLink' element={<ProductLineArticlesPage/>} />

          <Route path='/:market?/articles' element={<ArticleSearchPage/>} />
          <Route path='/:market?/embeded/search' element={<EmbededSearchPage/>} />
          <Route path='/:market?/embeded/articles/:articleNumber' element={<EmbededArticlePage/>} />

          { process.env.REACT_APP_PART_FINDER_ENABLED?.toString() == 'true' &&
            <Route path='/:market?/partFinder/:id' element={<PartFinderItemPage/>} />
          }
          { process.env.REACT_APP_PART_FINDER_ENABLED?.toString() == 'true' &&
            <Route path='/:market?/partFinder' element={<PartFinderSearchPage/>} />
          }

          <Route path='/:market?/articles/:articleNumber' element={<ArticlePage/>} />
          <Route path='/:market?/articles/Knowledge/:title' element={<ArticlePage/>} />
          
          <Route path='/:market?/case/create' element={<CaseCreatePage />} />
          <Route path='/:market?/case/create/:option' element={<CaseSuboptionsPage />} />
          <Route path='/:market?/case/create/:option/:suboption' element={<CaseFormPage />} />
          <Route path='/:market?/case/create/confirmation/:caseNumber' element={<CaseConfirmationPage />} />
        </Routes>

        {location.pathname.startsWith('/embeded') == false &&
          <FooterContainer>
            <SiteFooter 
              id="helpu-footer" 
              toTopText={t('TOP', { ns: 'cases' })}
              sitename='Support'
              copyrightText={
                t(`Copyright © {{year}} Laerdal Medical. All Rights Reserved.`, 
                    { ns: 'landingPage', year: new Date().getFullYear()})
                  }
              bottomLinks={[
                  {
                    label: t('Terms of Use', { ns: 'landingPage' }),
                    to: 'https://laerdal.com/support/customer-service/terms-of-use/',
                  },
                  {
                    label: t('Privacy Statement', { ns: 'landingPage' }),
                    to: 'https://laerdal.com/support/customer-service/privacy-statement/',
                  },
                  {
                    label: t('Accessibility statement', { ns: 'landingPage' }),
                    to: 'https://laerdal.com/support/customer-service/accessibility/',
                  },
                  {
                    label: t('Sustainability', { ns: 'landingPage' }),
                    to: 'https://laerdal.com/about-us/sustainability/',
                  }
                ]}
              />
          </FooterContainer>
        }
        </ContentContainer>
    </React.Suspense>
  );
}

export default App;